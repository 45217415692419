<template>
  <div class="imei-storage-time">
    <KTCodePreview v-bind:title="'Tuổi kho theo NH máy'">
      <template v-slot:preview>
        <b-row class="mb-4">
          <b-col md="3">
            <BDropdownCustom
              v-model="value"
              :options="filterdOptions"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
            />
          </b-col>
          <b-col md="3">
            <Autosuggest
              :model="selectedCateName"
              :suggestions="filteredCateOptions"
              placeholder="danh mục sản phẩm"
              @select="onSelectedCate"
              @change="onInputCateChange"
              suggestionName="suggestionName"
            />
          </b-col>
        </b-row>
        <b-row class="pl-0">
          <b-col md="10" class="pl-0">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter()"
              class="col-md-8 mb-4 ml-4"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <div>
          <apexchart
            :options="chartOptions"
            :series="series"
            v-if="!onLoading"
          ></apexchart>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss">
.imei-storage-time {
  .text-title-input-form {
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  .d-flex {
    display: -webkit-box !important;
  }
  .text-title {
    font-weight: 600;
    font-size: 16px;
  }
  .text-lable-total {
    font-weight: 600;
  }
  .text-report {
    font-weight: 500;
    font-size: 13px;
  }
  .labelInput {
    font-weight: 600;
  }

  .inputText {
    width: 22%;
  }
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .dropdown-header {
    color: #3699ff !important;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { removeAccents } from '@/utils/common';
import { cloneDeep, map, find, assign } from 'lodash';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  data() {
    return {
      onLoading: true,
      value: [],
      options: [],
      filterdOptions: [],
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '45%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'SL (máy)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' máy';
            },
          },
        },
      },
      cateOptions: [],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
    };
  },
  components: {
    KTCodePreview,
    BDropdownCustom,
    Autosuggest,
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Tuổi kho theo NH máy',
        route: '/storage-time-reports/by-cate-imei',
      },
      { title: 'Tuổi kho theo NH máy' },
    ]);
    this.fetchStoreByUser();
  },
  created() {
    this.fetchCategory();
  },
  methods: {
    fetchData: function () {
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      }
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }

      const param = {
        storeIds: listId,
        categoryId: this.selectedCateId,
      };

      const paramQuery = {
        params: param,
      };

      this.onLoading = true;
      ApiService.query('storage-time-reports/by-cate-imei', paramQuery).then(
        ({ data }) => {
          this.chartOptions.xaxis.categories = data.data.categories;
          this.series = data.data.dataSeries;
          this.onLoading = false;
        },
      );
    },
    onSelect(option) {
      const index = this.options.findIndex((item) => item.name == option.name);
      if (index !== -1) this.options[index].checked = true;
      this.filterdOptions = cloneDeep(this.options);
    },

    onRemove(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
      this.filterdOptions = cloneDeep(this.options);
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onFilter() {
      this.$router.push({
        name: 'storage-time-by-cate',
      });
      this.fetchData();
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.options = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            let option = {
              code: element.id,
              name: element.shortName,
              checked: false,
            };
            this.options.push(option);
            if (!data.data.viewAll) {
              let index = this.options.findIndex(
                (item) => item.code == element.id,
              );
              this.options[index].checked = true;
              this.value.push(this.options[index]);
            }
          });
          this.filterdOptions = cloneDeep(this.options);
        }
        this.fetchData();
      });
    },
    onInputed(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.options);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptions = map(options, (obj) => {
          return assign(obj, find(this.filterdOptions, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptions, { id: obj.id }));
      });

      this.filterdOptions = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          const item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions];
      });
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredCateOptions = [...filteredData];
    },
  },
};
</script>
